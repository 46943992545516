class FilesServiceClass {
    public socket: any;
   // private namespace: string;

    constructor(_socket,_namespace) {
        this.socket = _socket;
       // this.namespace = _namespace;
    }


    savefile(filename:string, base64:string | ArrayBuffer | null, metadata:any) {
        return new Promise<string>(async (resolve, reject) => {
            try {
                this.socket.emit('SAVEFILE', filename, base64, metadata, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    getfile(id:string) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('GETFILE', id, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    deletefile(id) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('DELETEFILE', id, function (err) {
                    if(err) { return reject(err); }
                    resolve("");
                });
            } catch (err) {
                reject(err);
            }
        });
    }

 
}